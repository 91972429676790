import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, isRef as _isRef, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex gap-4 items-center" }
const _hoisted_2 = { class: "grid gap-0" }
const _hoisted_3 = { class: "grid gap-2 items-center" }
const _hoisted_4 = { class: "bg-hub-background rounded-md h-min hover:opacity-80" }

import { router } from '@/core/router';
	import { ref } from 'vue';
	import { usePubHubs } from '@/core/pubhubsStore';
	import { useDialog } from '@/store/dialog';
	import { useI18n } from 'vue-i18n';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'RoomPill',
  props: {
		room: Object,
		roomIsSecure: Boolean,
	},
  setup(__props) {

	const pubhubs = usePubHubs();
	const { t } = useI18n();

	let expanded = ref(false);
	const props = __props;

	function expandPillToggle() {
		expanded.value = !expanded.value;
	}

	async function joinRoom() {
		if (props.room?.room_id) {
			if (props.roomIsSecure === true) {
				router.push({ name: 'secure-room', params: { id: props.room.room_id } });
			} else {
				const dialog = useDialog();
				if (await dialog.okcancel(t('rooms.join_sure'), t('admin.name') + ': ' + props.room.name)) {
					await pubhubs.joinRoom(props.room.room_id);
				}
			}
		}
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_H3 = _resolveComponent("H3")!

  return (_openBlock(), _createElementBlock("div", {
    class: "flex max-w-[22rem] min-w-56 h-min rounded-md bg-white-middle text-black dark:bg-gray dark:text-white overflow-hidden",
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_isRef(expanded) //@ts-ignore
 ? expanded.value = false : expanded = false))
  }, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "w-3 shrink-0 bg-hub-background-5" }, null, -1)),
    _createElementVNode("div", {
      class: _normalizeClass(["flex justify-between p-2 py-3 gap-2 w-full", { 'gap-4': _unref(expanded) }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Icon, {
          type: __props.roomIsSecure ? 'shield' : 'speech_bubbles',
          class: "shrink-0"
        }, null, 8, ["type"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_H3, {
            class: _normalizeClass(["font-semibold line-clamp-1 m-0", { 'line-clamp-2': _unref(expanded) }])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(__props.room?.name), 1)
            ]),
            _: 1
          }, 8, ["class"]),
          _createElementVNode("p", {
            class: _normalizeClass(["text-xs line-clamp-1 italic", { 'line-clamp-3': _unref(expanded) }])
          }, _toDisplayString(__props.room?.topic), 3)
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Icon, {
            type: "chevron-down",
            class: _normalizeClass(["hover:cursor-pointer transition-all", { 'rotate-180': _unref(expanded) }]),
            size: "lg",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (expandPillToggle()))
          }, null, 8, ["class"])
        ]),
        _createVNode(_component_Icon, {
          type: "join_room",
          size: "lg",
          class: _normalizeClass(["hover:cursor-pointer hover:opacity-80", { hidden: !_unref(expanded) }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (joinRoom()))
        }, null, 8, ["class"])
      ])
    ], 2)
  ], 32))
}
}

})