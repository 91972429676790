import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-full text-hub-text"
}
const _hoisted_2 = {
  key: 0,
  class: "md:grid grid-cols-8 h-full"
}
const _hoisted_3 = { class: "flex justify-between gap-4 items-end border-b h-full py-2 pl-5 mr-8" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "font-normal" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 1 }

import { usePubHubs } from '@/core/pubhubsStore';
	import { LOGGER } from '@/dev/Logger';
	import { SMI } from '@/dev/StatusMessage';
	import { useDialog } from '@/store/dialog';
	import { useMenu } from '@/store/menu';
	import { usePlugins } from '@/store/plugins';
	import { HubInformation, featureFlagType, Message, MessageBoxType, MessageType, RoomType, Theme, TimeFormat, useHubSettings, useMessageBox, useRooms, useSettings, useUser } from '@/store/store';
	import { getCurrentInstance, onMounted, ref, watch } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { RouteParamValue, useRouter } from 'vue-router';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

	const { locale, availableLocales } = useI18n();
	const router = useRouter();
	const settings = useSettings();
	const hubSettings = useHubSettings();
	const user = useUser();
	const rooms = useRooms();
	const messagebox = useMessageBox();
	const dialog = useDialog();
	const pubhubs = usePubHubs();
	const plugins = usePlugins();
	const menu = useMenu();
	const settingsDialog = ref(false);
	const avatar = ref();
	const setupReady = ref(false);
	const disclosureEnabled = settings.isFeatureEnabled('disclosure');

	watch(
		() => rooms.totalUnreadMessages,
		() => {
			rooms.sendUnreadMessageCounter();
		},
	);

	watch(
		() => user.avatarUrl,
		() => {
			avatar.value = user.avatarUrl;
		},
	);

	onMounted(() => {
		plugins.setPlugins(getCurrentInstance()?.appContext.config.globalProperties._plugins, router);
	});

	onMounted(async () => {
		LOGGER.log(SMI.STARTUP_TRACE, 'App.vue onMounted');

		settings.initI18b({ locale: locale, availableLocales: availableLocales });
		// set language when changed
		settings.$subscribe(() => {
			locale.value = settings.getActiveLanguage;
		});

		if (window.location.hash !== '#/hub/') {
			await pubhubs.login();
			setupReady.value = true; // needed if running only the hub-client
			router.push({ name: 'home' });
		}
		await startMessageBox();

		LOGGER.log(SMI.STARTUP_TRACE, 'App.vue onMounted done');
	});

	async function startMessageBox() {
		let messageBoxStarted = false;

		if (!hubSettings.isSolo) {
			await messagebox.init(MessageBoxType.Child, hubSettings.parentUrl);

			// Ask for Hub name etc.
			messagebox.addCallback(MessageType.HubInformation, (message: Message) => {
				settings.hub = message.content as HubInformation;
			});

			// Listen to roomchange
			messagebox.addCallback(MessageType.RoomChange, (message: Message) => {
				const roomId = message.content as RouteParamValue;
				if (rooms.currentRoomId !== roomId) {
					router.push({ name: 'room', params: { id: roomId } });
				}
			});

			// Listen to sync settings
			messagebox.addCallback(MessageType.Settings, (message: Message) => {
				settings.setTheme(message.content.theme as Theme);
				settings.setTimeFormat(message.content.timeformat as TimeFormat);
				settings.setLanguage(message.content.language);

				messageBoxStarted = true;
			});

			//Listen to global menu change
			messagebox.addCallback(MessageType.BarHide, () => {
				hubSettings.mobileHubMenu = false;
			});

			messagebox.addCallback(MessageType.BarShow, () => {
				hubSettings.mobileHubMenu = true;
			});

			// Wait for theme change happened
			const wait = setInterval(() => {
				if (messageBoxStarted) {
					setupReady.value = true;
					clearInterval(wait);
				}
			}, 250);
			setTimeout(() => {
				clearInterval(wait);
				setupReady.value = true;
			}, 2500);
		}
	}

return (_ctx: any,_cache: any) => {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_TruncatedText = _resolveComponent("TruncatedText")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_H2 = _resolveComponent("H2")!
  const _component_RoomList = _resolveComponent("RoomList")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_DiscoverUsers = _resolveComponent("DiscoverUsers")!
  const _component_HeaderFooter = _resolveComponent("HeaderFooter")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Disclosure = _resolveComponent("Disclosure")!
  const _component_SettingsDialog = _resolveComponent("SettingsDialog")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_unref(settings).getActiveTheme, "h-full"])
  }, [
    (setupReady.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_unref(user).isLoggedIn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_HeaderFooter, {
                  class: _normalizeClass(["md:col-span-2 md:flex gap-4 bg-hub-background-2", { hidden: !_unref(hubSettings).mobileHubMenu }])
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        (_unref(hubSettings).isSolo && _unref(settings).isFeatureEnabled(_unref(featureFlagType).notifications) && _unref(rooms).totalUnreadMessages > 0)
                          ? (_openBlock(), _createBlock(_component_Badge, {
                              key: 0,
                              class: "-ml-2 -mt-2"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(rooms).totalUnreadMessages), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_router_link, {
                          to: "/",
                          class: "flex"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Logo, { class: "inline-block h-12" }),
                            _createVNode(_component_TruncatedText, { class: "mt-6" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(settings).hub.name), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", null, [
                        _createVNode(_component_Avatar, {
                          userId: _unref(user).user.userId,
                          img: avatar.value,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => {
										settingsDialog.value = true;
										_unref(hubSettings).hideBar();
									}),
                          class: "cursor-pointer w-8 h-8 text-md"
                        }, null, 8, ["userId", "img"])
                      ])
                    ])
                  ]),
                  footer: _withCtx(() => [
                    (_unref(disclosureEnabled) && _unref(user).isAdmin)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createVNode(_component_H2, { class: "pl-5 border-b mr-8" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('menu.moderation_tools')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_Menu, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_MenuItem, {
                                to: { name: 'ask-disclosure' },
                                icon: "sign"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('menu.moderation_tools_disclosure')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (_unref(user).isAdmin)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createVNode(_component_H2, { class: "pl-5 border-b mr-8" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('menu.admin_tools')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_Menu, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_MenuItem, {
                                to: { name: 'admin' },
                                icon: "admin"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('menu.admin_tools_rooms')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_Menu, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menu).getMenu, (item, index) => {
                          return (_openBlock(), _createBlock(_component_MenuItem, {
                            key: index,
                            to: item.to,
                            icon: item.icon,
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(hubSettings).hideBar()))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t(item.key)), 1)
                            ]),
                            _: 2
                          }, 1032, ["to", "icon"]))
                        }), 128))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_H2, { class: "pl-5 border-b mr-8" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('menu.rooms')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_RoomList),
                    _createVNode(_component_RouterLink, { to: '/discoverRooms' }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Button, {
                          class: "mx-auto py-1 my-2 w-5/6",
                          color: 'gray'
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Icon, {
                              type: "compass",
                              class: "absolute left-0 top-0 -ml-1 -mt-2"
                            }),
                            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('rooms.discover')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_H2, { class: "pl-5 border-b mr-8" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('menu.private_rooms')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_RoomList, {
                      roomType: _unref(RoomType).PH_MESSAGES_DM
                    }, null, 8, ["roomType"]),
                    _createVNode(_component_DiscoverUsers)
                  ]),
                  _: 1
                }, 8, ["class"]),
                _createElementVNode("div", {
                  class: _normalizeClass(["md:col-span-6 md:block dark:bg-gray-middle h-full overflow-y-auto scrollbar", { hidden: _unref(hubSettings).mobileHubMenu }])
                }, [
                  _createVNode(_component_router_view)
                ], 2)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_router_view)
              ]))
        ]))
      : _createCommentVNode("", true),
    (_unref(disclosureEnabled))
      ? (_openBlock(), _createBlock(_component_Disclosure, { key: 1 }))
      : _createCommentVNode("", true),
    (settingsDialog.value)
      ? (_openBlock(), _createBlock(_component_SettingsDialog, {
          key: 2,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (settingsDialog.value = false))
        }))
      : _createCommentVNode("", true),
    (_unref(dialog).visible)
      ? (_openBlock(), _createBlock(_component_Dialog, {
          key: 3,
          onClose: _unref(dialog).close
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})