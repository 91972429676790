import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = ["title"]

import { onMounted, ref } from 'vue';
	import { useRouter } from 'vue-router';
	import { TPublicRoom, useRooms } from '@/store/store';
	import { usePubHubs } from '@/core/pubhubsStore';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'DiscoverRooms',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

	const rooms = useRooms();
	const router = useRouter();
	const pubhubs = usePubHubs();
	const emit = __emit;
	const showList = ref(false);

	onMounted(async () => {
		await rooms.fetchPublicRooms();
	});

	function focus(focus: boolean) {
		showList.value = focus;
	}

	async function joinRoom(room: TPublicRoom) {
		if (rooms.roomIsSecure(room.room_id)) {
			router.push({ name: 'secure-room', params: { id: room.room_id } });
		} else {
			await pubhubs.joinRoom(room.room_id);
		}
		close();
	}

	async function close() {
		focus(false);
		emit('close');
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_FilteredList = _resolveComponent("FilteredList")!

  return (_openBlock(), _createElementBlock("div", {
    class: "pl-6 pr-8 relative",
    onFocusin: _cache[1] || (_cache[1] = ($event: any) => (focus(true))),
    onClick: _cache[2] || (_cache[2] = ($event: any) => (focus(true))),
    onKeydown: _cache[3] || (_cache[3] = _withKeys(($event: any) => (focus(false)), ["esc"])),
    onMouseleave: _cache[4] || (_cache[4] = ($event: any) => (focus(false)))
  }, [
    _createVNode(_component_Icon, {
      type: "compass",
      class: "absolute -ml-2 bg-white dark:bg-hub-background-2"
    }),
    _createVNode(_component_FilteredList, {
      items: _unref(rooms).visiblePublicRooms,
      sortby: "name",
      placeholder: _ctx.$t('rooms.discover'),
      inputClass: 'pl-6',
      listClass: '-mt-[17px] border rounded-md shadow-md',
      showCompleteList: showList.value,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (joinRoom($event)))
    }, {
      item: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Icon, {
            type: _unref(rooms).roomIsSecure(item.room_id) ? 'shield' : 'speech_bubbles',
            class: "flex-none mr-4 text-blue group-hover:text-black"
          }, null, 8, ["type"]),
          _createElementVNode("span", {
            title: item.room_id,
            class: "grow truncate w-100"
          }, _toDisplayString(item.name) + " ", 9, _hoisted_2),
          _createVNode(_component_Icon, {
            type: "plus",
            class: "flex-none"
          })
        ])
      ]),
      _: 1
    }, 8, ["items", "placeholder", "showCompleteList"])
  ], 32))
}
}

})