import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex gap-2 w-full justify-between items-center" }
const _hoisted_2 = {
  key: 0,
  class: "flex gap-2 group-hover:hidden transition-all duration-200 ease-in-out"
}

import { useI18n } from 'vue-i18n';
	import { useRouter } from 'vue-router';
	import { Room, useRooms, useDialog, RoomType, useUser, useHubSettings } from '@/store/store';
	import { usePubHubs } from '@/core/pubhubsStore';
	import { usePlugins, PluginProperties } from '@/store/plugins';
	import { NotificationCountType } from 'matrix-js-sdk';
	import { useSettings, featureFlagType } from '@/store/store';
	import { isVisiblePrivateRoom } from '@/core/privateRoomNames';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'RoomList',
  props: {
		roomType: {
			type: String,
			default: '!' + RoomType.PH_MESSAGES_DM,
		},
	},
  setup(__props) {

	const settings = useSettings();
	const hubSettings = useHubSettings();

	const { t } = useI18n();
	const router = useRouter();
	const rooms = useRooms();
	const pubhubs = usePubHubs();
	const plugins = usePlugins();

	const props = __props;

	// Either private room or public room based on roomType given as prop (private or normal)
	// Needs a bit of refacturing, not so clear now.
	function showRoom(room: Room): Boolean {
		// if no specific type is set, allways show this room
		if (props.roomType !== '') {
			const type = props.roomType.substring(1);
			// If not (given room type), just show
			if (props.roomType.charAt(0) === '!') {
				return room.getType() !== type;
			} else {
				const roomType = room.getType();
				if (roomType === RoomType.PH_MESSAGES_DM) {
					// Check if private room is visible for this user BUSY
					const user = useUser();
					return isVisiblePrivateRoom(room.name, user.user);
				} else {
					return room.getType() === props.roomType;
				}
			}
		}
		return true;
	}

	async function leaveRoom(roomId: string) {
		const room = rooms.room(roomId);
		if (room) {
			const dialog = useDialog();
			if (room.isPrivateRoom()) {
				if (await dialog.okcancel(t('rooms.hide_sure'))) {
					await pubhubs.setPrivateRoomHiddenStateForUser(room, true);
					await router.replace({ name: 'home' });
				}
			} else {
				if (await dialog.okcancel(t('rooms.leave_sure'))) {
					await pubhubs.leaveRoom(roomId);
					await router.replace({ name: 'home' });
				}
			}
		}
	}

	function roomIcon(room: Room): string {
		let icon = 'speech_bubbles';
		const plugin = plugins.hasRoomPlugin(room) as PluginProperties;
		if (plugin.icon) {
			icon = plugin.icon;
		}
		return icon;
	}

return (_ctx: any,_cache: any) => {
  const _component_InlineSpinner = _resolveComponent("InlineSpinner")!
  const _component_PrivateRoomName = _resolveComponent("PrivateRoomName")!
  const _component_RoomName = _resolveComponent("RoomName")!
  const _component_TruncatedText = _resolveComponent("TruncatedText")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_unref(rooms).roomsLoaded)
      ? (_openBlock(), _createBlock(_component_InlineSpinner, {
          key: 0,
          class: "ml-4"
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_Menu, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(rooms).sortedRoomsArray, (room) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: room.roomId
          }, [
            (showRoom(room))
              ? (_openBlock(), _createBlock(_component_MenuItem, {
                  to: { name: 'room', params: { id: room.roomId } },
                  roomInfo: room,
                  icon: roomIcon(room),
                  key: room.roomId,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(hubSettings).hideBar())),
                  class: "group inline-block w-full"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_1, [
                      _createVNode(_component_TruncatedText, null, {
                        default: _withCtx(() => [
                          (room.isPrivateRoom())
                            ? (_openBlock(), _createBlock(_component_PrivateRoomName, {
                                key: 0,
                                members: room.getOtherJoinedAndInvitedMembers()
                              }, null, 8, ["members"]))
                            : (_openBlock(), _createBlock(_component_RoomName, {
                                key: 1,
                                room: room
                              }, null, 8, ["room"]))
                        ]),
                        _: 2
                      }, 1024),
                      (_unref(settings).isFeatureEnabled(_unref(featureFlagType).notifications))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                            (room.getRoomUnreadNotificationCount(_unref(NotificationCountType).Total) > 99)
                              ? (_openBlock(), _createBlock(_component_Badge, {
                                  key: 0,
                                  class: "text-xxs",
                                  color: "hub"
                                }, {
                                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                                    _createTextVNode("99+")
                                  ])),
                                  _: 1
                                }))
                              : (room.getRoomUnreadNotificationCount(_unref(NotificationCountType).Total) > 0)
                                ? (_openBlock(), _createBlock(_component_Badge, {
                                    key: 1,
                                    color: "hub"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(room.getRoomUnreadNotificationCount(_unref(NotificationCountType).Total)), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true),
                            (room.getRoomUnreadNotificationCount(_unref(NotificationCountType).Highlight) > 0)
                              ? (_openBlock(), _createBlock(_component_Badge, {
                                  key: 2,
                                  color: "hub"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_Icon, {
                                      type: "mention",
                                      size: "sm",
                                      class: "shrink-0"
                                    })
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_Icon, {
                        type: "unlink",
                        class: "cursor-pointer hover:text-red-light stroke-2 group-hover:inline-block hidden transition-all duration-200 ease-in-out",
                        onClick: _withModifiers(($event: any) => (leaveRoom(room.roomId)), ["prevent"])
                      }, null, 8, ["onClick"])
                    ])
                  ]),
                  _: 2
                }, 1032, ["to", "roomInfo", "icon"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]),
      _: 1
    })
  ], 64))
}
}

})